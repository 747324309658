import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Layout from 'components/Layout'
import Link from 'components/Link'
import SectionGetStarted from 'components/SectionGetStarted'

const popover = (id, text) => <Popover id={id}>{text}</Popover>

const PricingPage = ({ location }) => (
  <Layout
    title="UseData as a Service"
    description="UseData offers an affordable hosted solution so you don't need to worry about setting up and updating your UseData servers"
    location={location}
  >
    <section className="section">
      <div className="container">
        <h1 className="text-center mb-xs">
          Plans that scale with your data growth
        </h1>
        <h4 className="text-center font-light">
          Easy setup. Query and visualize in minutes. No credit card required.
        </h4>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="flex-content-center pricing-plan-container">
          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-starter.png" />
                </div>
                <div className="pricing-name">STARTER</div>
                <div className="pricing-charge">
                  $50<sub>/month</sub>
                </div>
                <div className="pricing-description">
                  <p>50 Credits, 2 Users</p>
                  <p>
                    30 minutes minimum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        1,
                        'How often queries can be scheduled to automatically refresh in the background.'
                      )}
                    >
                      <span className="dotted-helper">
                        automatic refresh frequency
                      </span>
                    </OverlayTrigger>
                  </p>
                  <p>
                    5 minutes maximum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        2,
                        'How long your query is allowed to run.'
                      )}
                    >
                      <span className="dotted-helper">
                        query execution time
                      </span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>

              <div className="pricing-notes">
                <Link
                  data-track
                  data-track-location="pricing-lite"
                  data-track-event="Start Trial"
                  to="http://app.usedata.io/signup?plan=starter"
                  className="btn btn-primary"
                >
                  Start Trial
                </Link>
              </div>
            </div>
          </div>

          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-pro.png" />
                </div>
                <div className="pricing-name">PROFESSIONAL</div>
                <div className="pricing-charge">
                  $200<sub>/month</sub>
                </div>
                <div className="pricing-description">
                  <p>500 Credits, 10 Users</p>
                  <p>
                    5 minutes minimum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        1,
                        'How often queries can be scheduled to automatically refresh in the background.'
                      )}
                    >
                      <span className="dotted-helper">
                        automatic refresh frequency
                      </span>
                    </OverlayTrigger>
                  </p>
                  <p>
                    2 hours maximum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        2,
                        'How long your query is allowed to run.'
                      )}
                    >
                      <span className="dotted-helper">
                        query execution time
                      </span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>

              <div className="pricing-notes">
                <Link
                  data-track
                  data-track-location="pricing-startup"
                  data-track-event="Start Trial"
                  to="http://app.usedata.io/signup?plan=pro"
                  className="btn btn-primary"
                >
                  Start Trial
                </Link>
              </div>
            </div>
          </div>
          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-business.png" />
                </div>
                <div className="pricing-name">BUSINESS</div>
                <div className="pricing-charge">
                  $500<sub>/month</sub>
                </div>
                <div className="pricing-description">
                  <p>Unlimited Credits, 10 Users</p>
                  <p>
                    1 minute minimum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        1,
                        'How often queries can be scheduled to automatically refresh in the background.'
                      )}
                    >
                      <span className="dotted-helper">
                        automatic refresh frequency
                      </span>
                    </OverlayTrigger>
                  </p>
                  <p>
                    12 hours maximum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        2,
                        'How long your query is allowed to run.'
                      )}
                    >
                      <span className="dotted-helper">
                        query execution time
                      </span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
              <div className="pricing-notes">
                <Link
                  data-track
                  data-track-location="pricing-business"
                  data-track-event="Start Trial"
                  to="http://app.usedata.io/signup?plan=business"
                  className="btn btn-primary"
                >
                  Start Trial
                </Link>
              </div>
            </div>
          </div>
          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-business.png" />
                </div>
                <div className="pricing-name">CUSTOMIZED</div>
                <div className="pricing-charge">
                  <sub>Contact Us</sub>
                </div>
                <div className="pricing-description">
                  <p>Fully Customized</p>
                  <p>
                    1 minute minimum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        1,
                        'How often queries can be scheduled to automatically refresh in the background.'
                      )}
                    >
                      <span className="dotted-helper">
                        automatic refresh frequency
                      </span>
                    </OverlayTrigger>
                  </p>
                  <p>
                    12 hours maximum{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        2,
                        'How long your query is allowed to run.'
                      )}
                    >
                      <span className="dotted-helper">
                        query execution time
                      </span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
              <div className="pricing-notes">
                <Link
                  data-track
                  data-track-location="pricing-business"
                  data-track-event="Start Trial"
                  to="http://app.usedata.io/signup?plan=customized"
                  className="btn btn-primary"
                >
                  Start Trial
                </Link>
              </div>
            </div>
          </div>          
        </div>

        <div className="row">
          <div className="col-md-12 text-center m-t-25">
            <i className="silver">
              Fair use policy: We reserve the right to contact and limit users
              who exceed what we consider fair use.
            </i>
          </div>
        </div>

      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2 className="text-center mb-lg h1">Resource Pricing</h2>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <div className="flex-content-center pricing-plan-container">
              <div className="pricing-resource">
                <div className="pricing-body">
                  <table>
                    <tr className="p-t-25">
                      <th>Resource</th>
                      <th className="text-right">Credits</th>
                    </tr>
                    <tr className="p-t-25">
                      <td>1 Data Source</td>
                      <td className="text-right">4</td>
                    </tr>
                    <tr className="p-t-25">
                      <td>1 Dashboard</td>
                      <td className="text-right">5</td>
                    </tr>
                    <tr className="m-t-10">
                      <td>1 Stream Destination</td>
                      <td className="text-right">2</td>
                    </tr>                                        
                    <tr className="m-t-10">
                      <td>100000 Records</td>
                      <td className="text-right">2</td>
                    </tr>                                        
                    <tr className="m-t-10">
                      <td>1 Query</td>
                      <td className="text-right">2</td>
                    </tr>                                        
                    <tr className="m-t-10">
                      <td>1 Stream</td>
                      <td className="text-right">1</td>
                    </tr>                                                                                                    
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2 className="text-center mb-lg h1">Frequently asked questions</h2>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <div className="content-item">
              <h3 className="font-regular">Is my data secure with UseData?</h3>
              <p>We're hosted on AWS and follow best practices:</p>
              <ul>
                <li>
                  All our servers are in a private network and are not
                  accessible from the Internet, except for the load balancer and
                  a bastion server for SSH (that does not have any credentials
                  on it).
                </li>
                <li>HTTPS/SSL for everything.</li>
                <li>2FA for our internal systems.</li>
              </ul>

              <p>
                If you have any specific concerns, please{' '}
                <Link to="/contact">contact</Link> us and we'll be happy to
                help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SectionGetStarted />
  </Layout>
)

export default PricingPage
